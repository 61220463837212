@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f2faff !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #169af4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #169af4;
}
.projectPopover .ant-popover-inner-content {
  background: #f9fcff 0% 0% no-repeat padding-box;
  box-shadow: 0px 22px 33px #0000001c;
  border: 1px solid #008ce95e;
}
.projectPopover .ant-popover-arrow {
  background-color: #f9fcff;
  border-top: 1px solid #008ce95e !important;
  border-left: 1px solid #008ce95e !important;
}

.projecthourlog .ant-popover-inner-content {
  padding: 0px;
}
.projecthourlog .ant-popover-inner {
  border-radius: 8px;
}
.validationhourlog .ant-popover-inner-content {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.timesheetRadiogroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #7070703b;
  border-bottom: 3px solid #1890ff;
  background: #eef8ff 0% 0% no-repeat padding-box;
}
.timesheetRadiogroup
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right: none;
}
.timesheetRadiogroup .ant-radio-button-checked {
}
.timesheetRadiogroup
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: inherit;
}
.timesheetRadiogroup .ant-radio-button-wrapper {
  /* padding: 10px !important; */
  padding-top: 3px;
  height: 38px;
  width: 210px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 23px #008ce912;
  border: 0.800000011920929px solid #7070703b;
}
